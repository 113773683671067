import React, { useState, useContext, useEffect } from 'react'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import { GenericAPIRequest } from "../../../api/helpers"
import { NotificationContext } from "../../../contexts/notificationContext"
import { useStores } from "../../../models/root-store"
import OrganizationSectionSkeleton from "../organizationSectionSkeleton"
import { toJS } from "mobx"
import ArtifactBox from "../artifactBox"
import AddButton from "../../Buttons/addButton"
import ArtifactCreationPopUp from "../../PopUps/ArtifactCreationPopUp"
import EmptyState from "../../Global/emptyState"



const OrganizationSection = () => {

  const { organizationStore, userStore } = useStores()
  const { userId, company, userEmail } = userStore.user || {}
  const [fiscalDateRange, setFiscalDateRange] = useState({})
  const [callTypes, setCallTypes] = useState([])
  const [callTypeInput, setCallTypeInput] = useState("")
  const [addCallTypeState, setAddCallTypeState] = useState(false)
  const { triggerNotification } = useContext(NotificationContext)
  const [loading, setLoading] = useState(true)
  const [showArtifactCreationPopUp, setShowArtifactCreationPopUp] = useState(false)
  const orgSettings = toJS(organizationStore.organizationConfig) || {}
  const artifactList = orgSettings.artifacts || []
  const [artifactsLoading, setArtifactsLoading] = useState(false)

  useEffect(() => {

    const fetchOrgSettings = async () => {
      if (!organizationStore.organizationConfig) {
        await organizationStore.getOrgSettings(company, userId)
      }
      const orgSettings = toJS(organizationStore.organizationConfig)
      if (orgSettings) {
        setCallTypes(orgSettings.callTypes)
        setFiscalDateRange({ start: orgSettings.fiscalYearStart, end: orgSettings.fiscalYearEnd })
      }
      setLoading(false)
    }

    fetchOrgSettings()
  }, [])



  const handleCallTypeAddition = async () => {
    if (callTypeInput.trim().length > 0) {
      const updatedCallTypes = [
        ...callTypes,
        callTypeInput,
      ];
      setCallTypes(updatedCallTypes)
      setCallTypeInput("")
      setAddCallTypeState(false)
      const res = await updateOrgSettings({
        user_id: userId,
        company: company,
        updates: { callTypes: updatedCallTypes },
      })
      if (res.kind == 'ok') {
        organizationStore.updateOrgSettings("callTypes", updatedCallTypes)
      }
    }
  }

  const updateOrgSettings = async (updatedFields) => {

    try {
      const res = await GenericAPIRequest("ZenoApp", "put", "/orgSettings/{company}", {}, {}, updatedFields)
      triggerNotification("success", "Success", "Successfuly updated organization settings")
      return { kind: "ok", data: res }
    } catch (e) {
      console.error("Error updating module:", e)
      triggerNotification("error", "Error when updating organization Settings", "Please retry")
      return { kind: "error", error: e }
    }
  }

  const handleCallTypeInputChange = (e) => {
    setCallTypeInput(e.target.value)
  }

  const handleFiscalDateStartChange = async (e) => {
    const newFiscalYearStart = e.target.value
    setFiscalDateRange(prevState => ({
      ...prevState,
      start: newFiscalYearStart
    }))
    const res = await updateOrgSettings({
      user_id: userId,
      company: company,
      updates: { fiscalYearStart: newFiscalYearStart },
    })
    if (res.kind == 'ok') {
      organizationStore.updateOrgSettings("fiscalYearStart", newFiscalYearStart)
    }

  }

  const handleAddArtifactClick = () => {
    setShowArtifactCreationPopUp(true)
  }



  const createArtifactPopUp = showArtifactCreationPopUp ?
    <ArtifactCreationPopUp header="Create artifact"
      body="Create an artifact to tune the role play creation process"
      leftButtonLabel="Cancel"
      rightButtonLabel="Save"
      leftButtonFunction={() => setShowArtifactCreationPopUp(false)}
      company={company}
      userId={userId}
      userEmail={userEmail}></ArtifactCreationPopUp>
    : null

  const handleFiscalDateEndChange = async (e) => {
    const newFiscalYearEnd = e.target.value
    setFiscalDateRange(prevState => ({
      ...prevState,
      end: newFiscalYearEnd
    }))
    const res = await updateOrgSettings({
      user_id: userId,
      company: company,
      updates: { fiscalYearEnd: newFiscalYearEnd },
    })
    if (res.kind == 'ok') {
      organizationStore.updateOrgSettings("fiscalYearEnd", newFiscalYearEnd)
    }
  }

  if (loading) {
    return (
      <div className=" max-w-7xl px-4 flex-1 ">
        <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
          <div>
            <div class="flex items-center gap-x-3">
              <h1 class="flex gap-x-3 text-lg leading-7">
                <span class="font-semibold text-grey">Organization</span>
              </h1>
            </div>
            <p class="mt-2 text-xs leading-6 text-gray-400">
              Configure your organization
            </p>
          </div>
        </div>
        <OrganizationSectionSkeleton></OrganizationSectionSkeleton>
      </div>
    )

  }

  return (
    <div className="max-w-7xl flex-1 px-4">
      <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
        <div>
          <div class="flex items-center gap-x-3">
            <h1 class="flex gap-x-3 text-base leading-7">
              <span class="font-semibold text-lg text-grey">Organization</span>
            </h1>
          </div>
          <p class="mt-2 text-xs leading-6 text-gray-400">
            Configure your organization
          </p>
        </div>
      </div>
      <form className="md:col-span-2">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">

          <div className="sm:col-span-3">
            <label htmlFor="fiscalStart" className="block text-sm font-medium leading-6 text-grey">Fiscal year start</label>
            <div className="mt-2">
              <input
                type="date"
                name="fiscalStart"
                id="fiscalStart"
                onChange={handleFiscalDateStartChange}
                value={fiscalDateRange.start}
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-grey shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-pareBlue sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="fiscalEnd" className="block text-sm font-medium leading-6 text-grey">Fiscal year end</label>
            <div className="mt-2">
              <input
                type="date"
                name="fiscalEnd"
                id="fiscalEnd"
                onChange={handleFiscalDateEndChange}
                value={fiscalDateRange.end}
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-grey shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-pareBlue sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="col-span-full">
            <fieldset>
              <div className="flex flex-col flex-1">
                <legend className="text-base font-semibold text-gray-900">Available Call types</legend>
                <legend className="text-xs leading-6 text-gray-400">Add call types to enable them across your teams</legend>
              </div>

              <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                {callTypes.map((callType) => (
                  <div key={callType} className="relative flex gap-3 py-4">
                    <div className="min-w-0 flex-1 text-sm/6">
                      <label className="select-none font-medium text-gray-900">
                        {callType}
                      </label>
                    </div>
                  </div>
                ))}

              </div>
            </fieldset>


          </div>

        </div>
      </form>
      {addCallTypeState ?
        <div key={"potentialAddition"} className="flex gap-3 py-4 grid grid-cols-2 gap-x-6 gap-y-8 sm:max-w-xl ">
          <div className="min-w-0 flex-1 text-sm/6">
            <input
              type="text"
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-grey shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-pareBlue sm:text-sm sm:leading-6"
              placeholder="Enter your call type"
              onChange={handleCallTypeInputChange}>
            </input>
          </div>
          <div className="flex h-6 shrink-0 items-center">
            <button
              className="text-pareBlue text-xs cursor-pointer mr-2"
              onClick={handleCallTypeAddition}>
              Confirm
            </button>
            <button
              className="text-red-500 text-xs cursor-pointer"
              onClick={() => setAddCallTypeState(false)}>
              Cancel
                    </button>
          </div>
        </div>
        : <PlusCircleIcon
          aria-hidden="true"
          className="h-8 w-8 text-pareBlue mt-4 cursor-pointer"
          onClick={() => setAddCallTypeState(true)} />}

      <div className="col-span-full mt-6">
        <fieldset>
          <div className="flex flex-row flex-1 justify-between mb-4">
            <div>
              <legend className="text-base font-semibold text-gray-900">Role play agent tuning</legend>
              <legend className="text-xs leading-6 text-gray-400">Tune your role play agents by uploading documents</legend>
            </div>
            <AddButton
              buttonFunction={handleAddArtifactClick}
              buttonLabel="Add"></AddButton>
          </div>
          {artifactList.length === 0 ?
            <EmptyState
              header="No documents uploaded"
              subHeader="Upload documents in order to tune role play creation agent"
              buttonLabel="Add"
              buttonFunction={null}
              icon="artifact"
              removeButton={true}></EmptyState>
            : <div className="w-full flex flex-row flex-wrap">
              {artifactList.map(art => (
                <ArtifactBox key={art.artifactId} name={art.artifactName} description={art.description}></ArtifactBox>
              ))}

            </div>}
        </fieldset>


      </div>
      {createArtifactPopUp}
    </div>
  )
}

export default OrganizationSection
