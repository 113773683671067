import React, { useState,useContext } from "react";
import { getSignedArtifactUrl } from "../../api/routes"
import { v4 as uuid } from "uuid"
import { NotificationContext } from "../../contexts/notificationContext"
import { useStores } from "../../models/root-store"


const ArtifactCreationPopUp = ({
  header,
  body,
  leftButtonLabel,
  rightButtonLabel,
  leftButtonFunction,
  company, 
  userId,
  userEmail
}) => {
  // State variables for File Name, Description, and Selected File
  const [fileName, setFileName] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading,setLoading] = useState(false)

  const { organizationStore } = useStores()
  const { triggerNotification } = useContext(NotificationContext)

  // Handler for when the user selects a file
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const validateArtifactCreationForm = () =>{

    if (fileName.trim().length < 1){
      triggerNotification('error', "Please enter a valid name")
      return false
    }else if(fileDescription.trim().length < 1){
      triggerNotification('error', "Please enter a valid description")
      return false
    }else if(!selectedFile){
      triggerNotification('error', "Please upload a doc, docx, or pdf file")
      return false
    }
    return true
  }

  // Handler for create button
  const uploadArtifactFile = async () => {
    if (validateArtifactCreationForm()){
      setLoading(true)
      const artifactId = uuid()
      const artifactFileType = selectedFile.name.replace(/.*\./, "")
      const signedUrlRes = await getSignedArtifactUrl(artifactId,company,userId,artifactFileType)
      if(signedUrlRes.kind === 'ok'){
        const signedUrl = signedUrlRes.data
        await fetch(signedUrl, {
          method: "PUT",
          headers: { "Content-Type": artifactFileType === "pdf" ? "application/pdf" : "application/msword" },
          body: selectedFile,
        })
        const artifactFileKey = `${company}/artifacts/artifact_${artifactId}.${artifactFileType}`
        organizationStore.uploadArtifact({user_id: userId,artifactFileKey, company, artifactId, description:fileDescription, artifactName: fileName, email: userEmail })
        triggerNotification('success',"Your artifact was uploaded successfully", "You'll get an email when the agent has been successfully tuned")
        leftButtonFunction()
      }else{
        triggerNotification('error',"There was an issue uploading your file")
      }
      setLoading(false)
    }
  }

  return (
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                  {header}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500 whitespace-pre-line">{body}</p>
                </div>
              </div>
            </div>

            {/* Inputs for File Name, Description, and File Upload */}
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">File Name</label>
              <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                placeholder="Enter file name"
              />
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">File Description</label>
              <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={fileDescription}
                onChange={(e) => setFileDescription(e.target.value)}
                placeholder="Enter file description"
              />
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">Upload File</label>
              <input
                type="file"
                accept=".pdf, .docx"
                className="mt-1 block w-full text-sm text-gray-700 file:mr-4 file:py-2 file:px-4 
                           file:rounded-md file:border-0
                           file:text-sm file:font-semibold
                           file:bg-pareBlue file:text-white
                           hover:file:bg-indigo-500"
                onChange={handleFileChange}
              />
            </div>

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              {/* Create button (right) */}
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-pareBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pareBlue sm:col-start-2"
                onClick={!loading ? uploadArtifactFile : null}
              >
                {!loading ? rightButtonLabel : "Saving..."}
              </button>

              {/* Cancel button (left) */}
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 cursor-pointer"
                onClick={!loading ? leftButtonFunction : null}
              >
                {leftButtonLabel}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtifactCreationPopUp;
