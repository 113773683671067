import { types, Instance, flow, SnapshotIn } from "mobx-state-tree"
import { getUsageData } from "../../api/routes"

const LoginDistributionFieldsModel = types
  .model("LoginDistributionFields", {
    timestamp: types.string,
    value: types.string
  })

const UsageSectionFieldsModel = types
  .model("UsageSectionsFields", {
    attemptCount: types.number,
    savedAttemptCount: types.string,
    savedPlaygroundRepCount: types.string,
    loginDistribution: types.optional(types.array(LoginDistributionFieldsModel), [])
  })

export type UsageSectionsSnapshotIn = SnapshotIn<typeof UsageSectionFieldsModel>

export const UsageStoreModel = types
  .model("UsageStore", {
    usageData: types.maybeNull(UsageSectionFieldsModel),
  })
  .actions(self => ({
    getUsage: flow(function* getUsage(company, user_id) {
      try {
        const usage = yield getUsageData(company, user_id)
        console.log(usage.data.usageStats)
        if (usage.kind === "ok") {
          self.usageData = UsageSectionFieldsModel.create(usage.data.usageStats)
          return true
        }
      } catch (e) {
        return false
      }
      return false
    }),
  }))

export type UsageStore = Instance<typeof UsageStoreModel>