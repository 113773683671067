import { types, flow, SnapshotIn, Instance } from "mobx-state-tree"
import { CreatePersona, GetSinglePersona, GetPersonas, UpdatePersona, CreatePersonaInstance, getAllPersonaInstancesByUserId } from "../../api/routes"


/* ------------------------------------------
   Main persona model
------------------------------------------ */
const PersonaFieldsModel = types.model("PersonaFields", {
  personaId: types.string,
  companyId: types.string,
  createdById: types.string,
  createdByName: types.string,
  creationDate: types.number,
  displayName: types.string,
  role: types.string,
  voice: types.string,
  personality: types.string,
  background: types.string,
  additionalInfo: types.maybeNull(types.string),
})

const PersonaInstanceFieldsModel = types.model("PersonaInstanceFields", {
  instanceId: types.string,
  userId: types.string,
  personaId: types.string,
  companyId: types.string,
  createdById: types.string,
  createdByName: types.string,
  creationDate: types.number,
  displayName: types.string,
  role: types.string,
  voice: types.string,
  personality: types.string,
  background: types.string,
  additionalInfo: types.maybeNull(types.string),
  callType: types.string,
  company: types.string,
  repContext: types.string,
  difficulty: types.string,
})

const PersonaListFieldsModel = types.model("PersonaListFields", {
  personaList: types.array(PersonaFieldsModel),
  personaInstanceList: types.array(PersonaInstanceFieldsModel),
})

export type PersonaListFieldsSnapshotIn = SnapshotIn<typeof PersonaListFieldsModel>
export type PersonaFieldsSnapshotIn = SnapshotIn<typeof PersonaFieldsModel>

export const PersonaListStoreModel = types
  .model("PersonaListStore", {
    userPersonas: types.maybeNull(PersonaListFieldsModel),
  })
  .actions(self => ({
    /* --------------------------------------
       1. Get a single persona
    -------------------------------------- */
    getPersona: flow(function* getPersona(companyId: string, personaId: string, userId: string) {
      try {
        const personaInfoRes = yield GetSinglePersona(companyId, personaId, userId)
        if (personaInfoRes.kind === "ok") {
          const newPersona = PersonaFieldsModel.create(personaInfoRes.data)
          if (!self.userPersonas) {
            self.userPersonas = PersonaListFieldsModel.create({
              personaList: [newPersona],
            })
          } else {
            self.userPersonas.personaList.push(newPersona)
          }
          return true
        }
      } catch (e) {
        console.error("[getPersona] Error:", e)
      }
      return false
    }),

    /* --------------------------------------
       2. Get all personas for a company
    -------------------------------------- */
    getPersonas: flow(function* getPersonas(companyId: string, userId: string) {
      try {
        const managerPersonaInfoRes = yield GetPersonas(companyId, userId)
        if (managerPersonaInfoRes.kind === "ok") {
          self.userPersonas = PersonaListFieldsModel.create({
            personaList: managerPersonaInfoRes.data.map((mod: any) => PersonaFieldsModel.create(mod)),
          })
          return true
        }
      } catch (e) {
        console.error("[getPersonas] Error:", e)
      }
      return false
    }),

    /* --------------------------------------
       3. Delete a persona
    -------------------------------------- */
    // deletePersona: flow(function* deletePersona(companyId: string, personaId: string, userId: string) {
    //   try {
    //     const response = yield DeletePersona(companyId, personaId, userId)
    //     if (response.kind === "ok") {
    //       // Remove the persona from the store
    //       const index = self.userPersonas ?.personaList.findIndex(mod => mod.personaId === personaId)
    //       if (index !== undefined && index > -1 && self.userPersonas) {
    //         self.userPersonas.personaList.splice(index, 1)
    //       }
    //       return true
    //     }
    //   } catch (e) {
    //     console.error("[deletePersona] Error:", e)
    //   }
    //   return false
    // }),

    /* --------------------------------------
       4. Update a persona 
    -------------------------------------- */
    updatePersona: flow(function* updatePersona(
      companyId: string,
      personaId: string,
      updatedFields: Partial<PersonaListFieldsSnapshotIn>,
    ) {
      try {
        const response = yield UpdatePersona(companyId, personaId, updatedFields)
        if (response.kind === "ok") {
          const index = self.userPersonas ?.personaList.findIndex(mod => mod.personaId === personaId)
          if (index !== undefined && index > -1 && self.userPersonas) {
            self.userPersonas.personaList[index] = PersonaFieldsModel.create(response.data)
          }
          return true
        }
      } catch (e) {
        console.error("[updatePersona] Error:", e)
      }
      return false
    }),
    /* --------------------------------------
       5. Create a persona 
    -------------------------------------- */
    createPersona: flow(function* createPersona(
      personaData: Partial<PersonaListFieldsSnapshotIn>,
    ) {
      try {
        const response = yield CreatePersona(personaData)
        if (response.kind === "ok") {
          const newPersona = PersonaFieldsModel.create(response.data)
          if (!self.userPersonas) {
            self.userPersonas = PersonaListFieldsModel.create({
              personaList: [newPersona],
            })
          } else {
            self.userPersonas.personaList.push(newPersona)
          }
          return true
        }
      } catch (e) {
        console.error("[createPersona] Error:", e)
      }
      return false
    }),
    /* --------------------------------------
       5. Create a persona Instance
    -------------------------------------- */
    createPersonaInstance: flow(function* createPersonaInstance(
      personaInstanceData: Partial<PersonaListFieldsSnapshotIn>,
    ) {
      try {
        const response = yield CreatePersonaInstance(personaInstanceData)

        if (response.kind === "ok") {
          const newPersonaInstance = PersonaInstanceFieldsModel.create(response.data)
          self.userPersonas.personaInstanceList.push(newPersonaInstance)
          return { kind: 'ok', data: response.data }
        }
      } catch (e) {
        console.error("[createPersonaInstance] Error:", e)
      }
      return { kind: 'error', data: null }
    }),
    getPersonasAndInstancesByUserId: flow(function* getPersonasAndInstancesByUserId(
      userId: string,
      companyId: string
    ) {
      try {
        const personaIntancesRes = yield getAllPersonaInstancesByUserId(userId)
        const personasRes = yield GetPersonas(companyId, userId)

        if (personasRes.kind === "ok" && personaIntancesRes.kind === 'ok') {
          const personaIntances = personaIntancesRes.data.map((pi: any) => PersonaInstanceFieldsModel.create(pi))
          const personas = personasRes.data.map((p: any) => PersonaFieldsModel.create(p))
          self.userPersonas = PersonaListFieldsModel.create({
            personaList: personas,
            personaInstanceList: personaIntances
          })
          return true
        } else if (personasRes.kind === "ok") {
          const personas = personasRes.data.map((p: any) => PersonaFieldsModel.create(p))
          self.userPersonas = PersonaListFieldsModel.create({
            personaList: personas,
            personaInstanceList: []
          })
          return true
        } else if (personaIntancesRes.kind === "ok") {
          const personaIntances = personaIntancesRes.data.map((pi: any) => PersonaInstanceFieldsModel.create(pi))
          self.userPersonas = PersonaListFieldsModel.create({
            personaList: [],
            personaInstanceList: personaIntances
          })
          return true
        }
      } catch (e) {
        console.error("[createPersonaInstance] Error:", e)
      }
      return false
    })
  }))
  .views(self => ({
    // Get a single persona
    getPersonaById(personaId) {
      if (!self.userPersonas) return null
      return self.userPersonas.personaList.find(m => m.personaId === personaId) || null
    },
    getAllPersonas() {
      if (!self.userPersonas) return []
      return self.userPersonas.personaList
    }
  }))

/* ------------------------------------------
   Store instance type
------------------------------------------ */
export type PersonaListStore = Instance<typeof PersonaListStoreModel>
