import React from "react"
import { DocumentIcon } from '@heroicons/react/20/solid'

const ArtifactBox = ({ name, description }) => {

  return (
    <div className="w-[200px] h-[120px] m-[10px] rounded-[5px] relative box-border border border-gray-200 border-[1px]">
      <div className=" flex flex-row items-center justify-start font-semibold text-gray-800 p-2">
        <DocumentIcon aria-hidden="true" className="h-5 w-4 text-pareBlue mr-1" />
      </div>
      <div className="flex flex-col flex-1 pr-2 pl-2 pb-2 ">
        <h1 className="text-base font-semibold text-gray-900 line-clamp-1">
          {name}
        </h1>
        <div className="text-xs leading-6 text-gray-400 line-clamp-2">
          {description}
        </div>
      </div>
    </div>
  )
}

export default ArtifactBox
