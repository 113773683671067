import React, { useEffect, useState, useRef, useContext } from "react"
import { Auth, API } from "aws-amplify"
import { ChevronDown } from "lucide-react"
import { mirage } from "ldrs"
import "./ActivityCreationPage.css"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { v4 as uuid } from "uuid"
import ChatTerminal from "../../components/ChatTerminal/ChatTerminal"
import { NotificationContext } from "../../contexts/notificationContext"
import CreateButton from "../../components/Buttons/createButton"
import BackButton from "../../components/Buttons/backButton"
import PublishButton from "../../components/Buttons/publishButton"
import ModuleCreationProgressTracker from "../../components/ActivityCreationPage/moduleCreationProgressTracker"
import { toJS } from "mobx"
import VoiceSamplerButton from "../../components/ActivityCreationPage/voiceSamplerButton"

mirage.register()

const ENDPOINT = process.env.REACT_APP_WS_ENDPOINT
let socket
const HEARTBEAT_INTERVAL = 60000

const ActivityCreationPage = ({ setCurrentContentSubSection }) => {
  const { userStore, organizationStore, moduleListStoreV2 } = useStores()
  const { userId, company, assistant_id } = userStore.user || {}
  const [showBasicInfoStep, setShowBasicInfoStep] = useState(true)
  const [testName, setTestName] = useState("")
  const [timeLimit, setTimeLimit] = useState("")
  const [testDesc, setTestDesc] = useState("")
  const [testObj, setTestObj] = useState("")
  const [publishing, setPublishing] = useState(false)
  const [savedContext, setSavedContext] = useState(null)
  const [savedRubric, setSavedRubric] = useState(null)
  const [rubricFile, setRubricFile] = useState(null)
  const [contextFile, setContextFile] = useState(null)
  const [links, setLinks] = useState("")
  const convoIndex = useRef(0)
  const rubricFileInfo = useRef({})
  const contextFileInfo = useRef({})
  const splitConvoIndex = useRef(0)
  const heartbeatIntervalRef = useRef(null)
  const [callType, setCallType] = useState("")
  const [callTypeOptions, setCallTypeOptions] = useState(["Cold Call", "Discovery Call"])
  const contextCreationMessages = useRef([])
  const [fullChatTurn, setFullChatTurn] = useState("")
  const scrollButtonRef = useRef(null)
  const transcriptContainerRef = useRef(null)
  const voiceOptions = {
    Sam: "Male 1",
    Bob: "Male 2",
    Jennifer: "Female 1",
    Bridgette: "Female 2",
    Margot: "Female 3",
    Alexa: "Female 4",
  }
  const [voice, setVoice] = useState("Sam")
  const [endContextConvo, setEndContextConvo] = useState(false)
  const [chatBotLoading, setChatBotLoading] = useState(false)
  const roleplayConvoOver = useRef(false)
  const [screenShareChecked, setScreenShareChecked] = useState(false)
  const [contextCreationInput, setContextCreationInput] = useState("")
  const { triggerNotification } = useContext(NotificationContext)

  // const handleScreenShareCheckBoxChange = () => {
  //   setScreenShareChecked(!screenShareChecked)
  // }
  const handleVoiceChange = event => {
    setVoice(event.target.value)
  }

  const handleKeyDown = e => {
    e.target.style.height = "inherit"
    e.target.style.height = `${e.target.scrollHeight}px`
  }

  const handleContextCreationInputChange = e => {
    setContextCreationInput(e.target.value)
  }

  const cleanupFunction = refresh => {
    sessionStorage.setItem("contextCreationRefresh", "N")
    const endPayloadObj = {
      user_id: userId,
      convoIndex: convoIndex.current,
      convoEnd: true,
      assistant_id,
      company
    }
    const endConvoPayload = { action: "createassistantcontext", message: endPayloadObj }
    if (socket.readyState !== 0) {
      socket.send(JSON.stringify(endConvoPayload))
    }
    if (!refresh) {
      socket.close()
    }
    contextCreationMessages.current = []
    convoIndex.current = 0
    roleplayConvoOver.current = null
    splitConvoIndex.current = 0
  }

  const startHeartbeat = () => {
    stopHeartbeat()

    heartbeatIntervalRef.current = setInterval(() => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        const heartBeatPayload = { action: "createassistantcontext", message: { heartbeat: true } }
        socket.send(JSON.stringify(heartBeatPayload))
      } else {
        console.log("WebSocket not open, stopping heartbeat")
        stopHeartbeat()
      }
    }, HEARTBEAT_INTERVAL)
  }

  const stopHeartbeat = () => {
    if (heartbeatIntervalRef.current) {
      clearInterval(heartbeatIntervalRef.current)
      heartbeatIntervalRef.current = null
    }
  }

  useEffect(() => {
    const fetchModules = async () => {
      if (!moduleListStoreV2.userModules) {
        await moduleListStoreV2.getModules(company, userId)
      }
    }
    const fetchOrgSettings = async () => {
      if (!organizationStore.organizationConfig) {
        await organizationStore.getOrgSettings(company, userId)
      }
      const orgSettings = toJS(organizationStore.organizationConfig)
      if (orgSettings && orgSettings.callTypes.length > 0) {
        setCallTypeOptions(orgSettings.callTypes)

      }
    }
    fetchModules()
    fetchOrgSettings()
    socket = new WebSocket(ENDPOINT)
    socket.onopen = () => {
      console.log("Succesfully opened WS")
      if (window.performance) {
        if (performance.navigation.type == 1) {
          console.log("This page is reloaded")
          if (sessionStorage.getItem("contextCreationRefresh") === "Y") {
            cleanupFunction(true)
          } else {
            sessionStorage.setItem("contextCreationRefresh", "N")
          }
        } else {
          console.log("This page is not reloaded")
        }
      }

      const payloadObj = {
        user_id: userId,
        convoIndex: convoIndex.current,
        assistant_id,
        company
      }
      const payload = { action: "createassistantcontext", message: payloadObj }
      socket.send(JSON.stringify(payload))
      startHeartbeat()
      setChatBotLoading(true)
    }
    socket.onerror = () => {
      console.log("error")
      triggerNotification(
        "error",
        "Session error",
        "Please refresh the page to continue. There was an error with the session.",
      )
      stopHeartbeat()
      setShowBasicInfoStep(true)
    }
    socket.onclose = () => {
      console.log("Please refresh the page to continue. The session has timed out.")
      setShowBasicInfoStep(true)
      stopHeartbeat()
    }

    let nextExpectedOrder = 0
    const messageBuffer = {}

    socket.onmessage = async event => {
      const eventObj = JSON.parse(event.data)

      if (eventObj.heartbeat) {
        console.log("HEARTBEAT")
        return
      }

      const { sentence, orderIdx } = eventObj

      messageBuffer[orderIdx] = sentence
      processBufferedMessages()
    }
    function processBufferedMessages() {
      console.log(messageBuffer)
      while (messageBuffer.hasOwnProperty(nextExpectedOrder)) {
        const currentText = messageBuffer[nextExpectedOrder]
        delete messageBuffer[nextExpectedOrder]
        handleOrderedMessage(currentText, nextExpectedOrder)
        nextExpectedOrder += 1
      }
    }
    function handleOrderedMessage(currentText, orderIdx) {
      let botSpeechSegment = currentText
      if (
        botSpeechSegment.trim().match(/--CONFIRMED--/gm) ||
        botSpeechSegment.trim().match(/### --CONFIRMED--/gm) ||
        botSpeechSegment.trim().match(/\*\*--CONFIRMED--\*\*/gm)
      ) {
        if (!roleplayConvoOver.current) {
          roleplayConvoOver.current = true
          splitConvoIndex.current = contextCreationMessages.current.length
        }
        if (orderIdx === 0) {
          const currSpeechSegment = botSpeechSegment.trim().replace(/--CONFIRMED--/gm, "")
          receiveResponse({ user: "Pare", text: currSpeechSegment.trim(), id: Date.now() })
        } else {
          const currSpeechSegment = botSpeechSegment.trim().replace(/--CONFIRMED--/gm, "")
          const lastIndex = contextCreationMessages.current.length - 1
          contextCreationMessages.current[lastIndex].text += currSpeechSegment
            .replace(/###/gm, "")
            .replace(/\*\*/gm, "")
          setFullChatTurn(prevString => prevString + currSpeechSegment)
        }
      } else if (
        botSpeechSegment.trim().match(/--RUBRICCONFIRMED--/gm) ||
        botSpeechSegment.trim().match(/### --RUBRICCONFIRMED--/gm) ||
        botSpeechSegment.trim().match(/\*\*--RUBRICCONFIRMED--\*\*/gm)
      ) {
        if (roleplayConvoOver.current) {
          setEndContextConvo(true)
        } else {
          triggerNotification(
            "error",
            "Something went wrong in the creation process",
            "Please refresh to restart the process",
          )
        }
        if (orderIdx === 0) {
          const currSpeechSegment = botSpeechSegment.trim().replace(/--RUBRICCONFIRMED--/gm, "")
          receiveResponse({ user: "Pare", text: currSpeechSegment.trim(), id: Date.now() })
        } else {
          const currSpeechSegment = botSpeechSegment.trim().replace(/--RUBRICCONFIRMED--/gm, "")
          const lastIndex = contextCreationMessages.current.length - 1
          contextCreationMessages.current[lastIndex].text += currSpeechSegment
            .replace(/###/gm, "")
            .replace(/\*\*/gm, "")
          setFullChatTurn(prevString => prevString + currSpeechSegment)
        }
      } else if (botSpeechSegment.trim() === "DONE") {
        console.log("DONE")
      } else {
        if (orderIdx === 0) {
          receiveResponse({
            user: "Pare",
            text: botSpeechSegment.replace(/###/gm, "").replace(/\*\*/gm, ""),
            id: Date.now(),
          })
        } else {
          const lastIndex = contextCreationMessages.current.length - 1
          contextCreationMessages.current[lastIndex].text += botSpeechSegment.replace(/###/gm, "").replace(/\*\*/gm, "")
          setFullChatTurn(prevString => prevString + botSpeechSegment)
        }
      }
      if (botSpeechSegment.trim() === "DONE") {
        console.log("DONE")
        nextExpectedOrder = -1
        convoIndex.current += 1
        stopLoading()
      }
    }

    return () => {
      cleanupFunction(false)
      stopHeartbeat()
    }
  }, [])

  const uploadContextFileToAssistant = async (contextFileName, filekey) => {
    const resBody = await API.post("ZenoApp", "/sendPersonaFile", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        user_id: userId,
        personaFilename: contextFileName,
        filekey,
        company
      },
    })

    return resBody.file_id
  }
  const uploadRubricFileToAssistant = async (rubricFileName, filekey) => {
    const resBody = await API.post("ZenoApp", "/sendRubricFile", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        user_id: userId,
        rubricFileName,
        filekey,
        company
      },
    })
    return resBody.file_id
  }
  const receiveResponse = botSpeakerTurn => {
    contextCreationMessages.current.push(botSpeakerTurn)
    setChatBotLoading(true)
  }
  const stopLoading = async () => {
    await wait(1000)
    setChatBotLoading(false)
  }

  function handleContextFileChange(e) {
    if (e.target.files[0] && /\.(doc|docx|pdf)$/i.test(e.target.files[0].name)) {
      setContextFile(e.target.files[0])
      setSavedContext(null)
      contextCreationMessages.current.push(
        { user: "user", text: e.target.files[0].name, id: Date.now() },
        {
          user: "Pare",
          text: `Are you sure you want want to upload ${e.target.files[0].name}?`,
          type: "contextFileUploadButton",
          id: Date.now() + 1,
        },
      )
    } else {
      triggerNotification("error", "File type not supported", "Please upload a .doc or pdf formatted file.")
    }
  }

  const handleRubricFileChange = e => {
    if (e.target.files[0] && /\.(doc|docx|pdf)$/i.test(e.target.files[0].name)) {
      setRubricFile(e.target.files[0])
      setSavedRubric(null)
      contextCreationMessages.current.push(
        { user: "user", text: e.target.files[0].name, id: Date.now() },
        {
          user: "Pare",
          text: `Are you sure you want want to upload ${e.target.files[0].name}?`,
          type: "rubricFileUploadButton",
          id: Date.now() + 1,
        },
      )
    } else {
      triggerNotification("error", "File type not supported", "Please upload a .doc or pdf formatted file.")
    }
  }

  const transitionToContextStep = () => {

    const moduleName = testName.trim()

    if (moduleName.length < 0) {
      triggerNotification("error", "Invalid module name", "Please enter a valid module name")
      return
    }
    if (parseInt(timeLimit) === 0 || parseInt(timeLimit) > 31) {
      triggerNotification(
        "error",
        "Invalid module time limit",
        "Please make sure time limit is between 1 and 30 minutes inclusive",
      )
      return
    }
    if (timeLimit.trim().length < 1
      || testDesc.trim().length < 1
      || testObj.trim().length < 1
      || !voice
      || callType === "") {
      triggerNotification("error", "Invalid module information", "Please fill out all the required information")
      return
    }

    if (moduleListStoreV2.doesModuleNameExist(moduleName)) {
      triggerNotification("error", "Invalid module name", "Module name already exists")
      return
    }

    setShowBasicInfoStep(false)

  }

  const uploadContextFile = async () => {
    setChatBotLoading(true)
    const signedUrlContextUpload = await API.get("ZenoApp", "/getSignedUrlDoc", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      queryStringParameters: {
        user_id: userId,
        contextFile: contextFile ? contextFile.name : null,
      },
    })

    const context_id = signedUrlContextUpload.context_id
    const contextFileName = contextFile ? contextFile.name : null
    const context_file_key = signedUrlContextUpload.file_key
    const contextFileType = contextFile.name.replace(/.*\./, "")
    await fetch(signedUrlContextUpload.contextSignedUploadUrl, {
      method: "PUT",
      headers: { "Content-Type": contextFileType === "pdf" ? "application/pdf" : "application/msword" },
      body: contextFile,
    })
    const context_file_id = await uploadContextFileToAssistant(contextFileName, context_file_key)
    contextFileInfo.current = { context_id, context_file_key, contextFileName, contextFileType }

    const payloadObj = {
      user_id: userId,
      convoIndex: convoIndex.current,
      context_file_id,
      assistant_id,
      company
    }
    const payload = { action: "createassistantcontext", message: payloadObj }
    socket.send(JSON.stringify(payload))
    convoIndex.current += 1
  }
  const uploadRubricFile = async () => {
    setChatBotLoading(true)
    const signedUrlRubricUpload = await API.get("ZenoApp", "/getSignedUrlDoc", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      queryStringParameters: {
        user_id: userId,
        rubricFile: rubricFile ? rubricFile.name : null,
      },
    })
    const rubric_id = signedUrlRubricUpload.rubric_id
    const rubric_file_key = signedUrlRubricUpload.file_key
    const rubricFileName = rubricFile ? rubricFile.name : null
    const rubricFileType = rubricFile.name.replace(/.*\./, "")

    await fetch(signedUrlRubricUpload.rubricSignedUploadUrl, {
      method: "PUT",
      headers: { "Content-Type": rubricFileType === "pdf" ? "application/pdf" : "application/msword" },
      body: rubricFile,
    })
    const rubric_file_id = await uploadRubricFileToAssistant(rubricFileName, rubric_file_key)
    rubricFileInfo.current = { rubric_id, rubric_file_key, rubricFileName, rubricFileType }
    const payloadObj = {
      user_id: userId,
      convoIndex: convoIndex.current,
      rubric_file_id,
      assistant_id,
      company
    }
    const payload = { action: "createassistantcontext", message: payloadObj }
    socket.send(JSON.stringify(payload))
    convoIndex.current += 1
  }

  const createModule = async () => {
    if (!publishing) {
      setPublishing(true)
      const newModule = {
        user_id: userId,
        activity_id: uuid(),
        manager_id: userId,
        createdByName: userStore.user.name,
        testName: testName,
        timeLimit: timeLimit,
        testType: callType,
        testDecription: testDesc,
        context_id: contextFileInfo.current.context_id,
        contextFilename: contextFileInfo.current.contextFileName,
        context_file_key: contextFileInfo.current.context_file_key,
        rubricFilename: rubricFileInfo.current.rubricFileName,
        contextPreset: savedContext,
        rubric_id: rubricFileInfo.current.rubric_id,
        rubric_file_key: rubricFileInfo.current.rubric_file_key,
        rubricPreset: savedRubric,
        company: company,
        testObj: testObj,
        links: links,
        voice: voice,
        roleplay_history: contextCreationMessages.current.slice(0, splitConvoIndex.current),
        rubric_history: contextCreationMessages.current.slice(
          splitConvoIndex.current,
          contextCreationMessages.current.length,
        ),
        presentation: screenShareChecked,
      }
      try {
        API.put("ZenoApp", "/createModule", {
          headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
          body: newModule,
        })
        moduleListStoreV2.addModuleFromLegacyFlow(newModule)
      } catch (e) {
        console.warn(e)
      }
      await wait(3000)
      setCurrentContentSubSection("Modules")
    } else {
      triggerNotification("error", "Publishing in progress", "You will be redirected shortly...")
    }
  }

  const wait = delay => {
    return new Promise(res => setTimeout(res, delay))
  }

  const handleCallTypeChange = e => {
    setCallType(e.target.value)
  }
  const handleLinksChange = e => {
    setLinks(e.target.value)
  }
  const handleTestObjChange = e => {
    setTestObj(e.target.value)
  }
  const handleTimeLimitChange = e => {
    setTimeLimit(e.target.value)
  }
  const handleTestDescChange = e => {
    setTestDesc(e.target.value)
  }
  const handleTestNameChange = e => {
    setTestName(e.target.value)
  }

  const respondToContextBot = async event => {
    event.preventDefault()
    if (contextCreationInput.trim().length > 0) {
      const exchange = { user: "user", text: contextCreationInput, id: Date.now() }
      contextCreationMessages.current.push(exchange)
      setFullChatTurn("")
      const payloadObj = {
        user_response: contextCreationInput,
        user_id: userId,
        convoIndex: convoIndex.current,
        assistant_id,
        company
      }
      const payload = { action: "createassistantcontext", message: payloadObj }
      socket.send(JSON.stringify(payload))
      convoIndex.current += 1
      setContextCreationInput("")
      setChatBotLoading(true)
    }
  }

  return (
    <div className={`w-full h-full flex flex-col`}>
      <div className="">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <div className="cursor-pointer" onClick={() => setCurrentContentSubSection("Modules")}>
                Modules
              </div>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{`Create New Roleplay`}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div class="sm:flex sm:items-center pt-2">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">Role Play Builder</h1>
          <p class="mt-2 text-sm text-gray-700">Create your modules with the role play builder</p>
        </div>
        {showBasicInfoStep ? (
          <div class="mt-6 pb-8 pt-4 flex items-center justify-end gap-x-6">
            <CreateButton buttonLabel="Continue" buttonFunction={transitionToContextStep}></CreateButton>
          </div>
        ) : (
            <BackButton buttonLabel="Back" buttonFunction={() => setShowBasicInfoStep(true)}></BackButton>
          )}
      </div>
      {showBasicInfoStep ? (
        <div>
          <div class="mt-2 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Enter a name for this role play module
              </label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <input
                  placeholder="Enter your module name"
                  value={testName}
                  onChange={handleTestNameChange}
                  type="text"
                  name="first-name"
                  id="first-name"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"></input>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Provide a description for this role play module
              </label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <textarea
                  placeholder="Enter your module description"
                  value={testDesc}
                  onChange={handleTestDescChange}
                  type="text"
                  name="last-name"
                  id="last-name"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6 resize"></textarea>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="email" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Outline the rep’s objective for this role play module
              </label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <textarea
                  placeholder="Enter your module objective"
                  value={testObj}
                  onChange={handleTestObjChange}
                  id="email"
                  name="email"
                  type="text"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6 resize"></textarea>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Choose the voice type of your context
              </label>
              <div class="mt-2 sm:col-span-2 sm:mt-0 flex flex-row ">
                <select
                  id="voices"
                  name="voices"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  onChange={handleVoiceChange}>
                  {Object.keys(voiceOptions).map((x, i) => (
                    <option key={i} value={x}>
                      {voiceOptions[x]}
                    </option>
                  ))}
                </select>
                <VoiceSamplerButton voice={voice}></VoiceSamplerButton>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Choose the call type
              </label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <select
                  id="callTypes"
                  name="callTypes"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  onChange={handleCallTypeChange}>
                  <option key={"nullOptions"} value={""}></option>
                  {callTypeOptions.map(x => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="city" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Set a time limit for this role (min)
              </label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <input
                  type="number"
                  placeholder="Select your module time limit (max 30 min)"
                  min="1"
                  max="30"
                  value={timeLimit}
                  onChange={handleTimeLimitChange}
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"></input>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Add link(s) to pre-work for reps to review before completing this role play module
              </label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <textarea
                  placeholder="Enter links to trainings or documentation separated by commas"
                  value={links}
                  onChange={handleLinksChange}
                  type="text"
                  name="links"
                  id="links"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6 resize"></textarea>
              </div>
            </div>
          </div>
        </div>
      ) : (
          <div className="w-full h-[60vh] pb-20 pt-8 flex">
            <ModuleCreationProgressTracker
              isContextCreationDone={roleplayConvoOver.current}
              isRubricCreationDone={endContextConvo}
            />
            <div className="w-full h-full rounded">
              <div className="hidden md:flex flex-col h-full overflow-y-hidden border-l border-r relative">
                <div className="flex-grow overflow-y-auto" ref={transcriptContainerRef}>
                  <ChatTerminal
                    scrollContainerRef={transcriptContainerRef}
                    scrollButtonRef={scrollButtonRef}
                    contextCreationMessages={contextCreationMessages.current}
                    stateUpdater={fullChatTurn}
                    loading={chatBotLoading}
                    uploadContextFile={uploadContextFile}
                    uploadRubricFile={uploadRubricFile}
                    messagesType={"roleplayCreation"}
                  />
                </div>
                <div className="absolute bottom-0 left-0 right-0 p-4">
                  <button
                    ref={scrollButtonRef}
                    className="p-2 bg-white text-gray-500 rounded-full hover:bg-gray-100 transition-colors absolute right-4 bottom-4 shadow-md flex items-center">
                    <ChevronDown className="mr-1 h-4 w-4" />
                    <span className="text-xs pr-1">View latest</span>
                  </button>
                </div>
              </div>
              <form class="pb-8">
                {chatBotLoading ? (
                  <div className="w-full h-7 px-8 flex items-center  justify-center">
                    <l-mirage size="60" speed="1.4" color="grey"></l-mirage>
                  </div>
                ) : endContextConvo ? (
                  <div className="w-full h-7 flex items-center justify-end">
                    <PublishButton
                      buttonLabel="Publish module"
                      buttonFunction={createModule}
                      functionInProgress={publishing}
                      functionInProgressLabel="Publishing module..."></PublishButton>
                  </div>
                ) : (
                      <>
                        <div class=" w-full flex flex-col items-end bg-gray-100 border border-gray-300 rounded-md shadow-sm p-2">
                          <textarea
                            type="text"
                            placeholder="Type your message..."
                            class="flex-grow border-none focus:ring-0 outline-none resize-none h-auto max-h-40 bg-gray-100 w-full overflow-y-auto p-2 text-sm rounded-md"
                            onKeyDown={handleKeyDown}
                            value={contextCreationInput}
                            onChange={handleContextCreationInputChange}
                          />
                          <div className="w-full flex justify-between mt-2 ">
                            <button
                              type="button"
                              class="UploadFileButton flex w-[50px] h-[25px] items-center justify-center text-gray-500 hover:text-blue-500 focus:outline-none ml-2 mt-1"
                              onClick={() => document.getElementById("file-upload").click()}>
                              <input
                                type="file"
                                onChange={roleplayConvoOver.current ? handleRubricFileChange : handleContextFileChange}
                                class="hidden"
                                id="file-upload"></input>
                            </button>
                            <button
                              class=" SubmitChatButton w-[60px] h-[25px] flex items-center justify-center bg-pareBlue text-white hover:text-blue-500 focus:outline-none mr-2 mt-1 rounded"
                              onClick={respondToContextBot}></button>
                          </div>
                        </div>
                      </>
                    )}
              </form>
            </div>
          </div>
        )}
    </div>
  )
}

export default observer(ActivityCreationPage)
