import { types, Instance, flow, SnapshotIn } from "mobx-state-tree"
import { getAgents } from "../../api/routes"


const AgentFieldsModel = types
  .model("AgentSectionsFields", {
    agentId: types.string,
    company: types.string,
    agentName: types.string,
    quotaGoal: types.number,
    quotaMetric: types.string,
    quotaMetricFieldName: types.string,
    salesforceObject: types.string,
    managerId: types.string,
    managerName: types.string,
    competencyProfileFreq: types.string,
    repScope: types.string,
    repScopeCount: types.number
  })


const AgentListFieldsModel = types
  .model("AgentListFields", {
    agentList: types.array(AgentFieldsModel)
  })

export type AgentListSnapshotIn = SnapshotIn<typeof AgentListFieldsModel>


export const AgentStoreModel = types
  .model("AgentStore", {
    agents: types.maybeNull(AgentListFieldsModel),
  })
  .actions(self => ({
    getAllAgents: flow(function* getAllAgents(user_id: string) {

      try {
        const agentRes = yield getAgents(user_id)
        console.log(agentRes.data)
        if (agentRes.kind === "ok") {
          self.agents = AgentListFieldsModel.create({ agentList: agentRes.data })
          return true
        }
      } catch (e) {
        return false
      }
      return false
    }),
  }))

export type AgentStore = Instance<typeof AgentStoreModel>