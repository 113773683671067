import { types, Instance, flow, SnapshotIn } from "mobx-state-tree"
import { getOrgSettingsConfig, AddArtifacts } from "../../api/routes"

const ArtifactFieldsModel = types
  .model("ArtifactFields", {
    artifactId: types.string,
    artifactName: types.string,
    oaiId: types.maybeNull(types.string),
    description: types.string,
    s3Location: types.maybeNull(types.string)
  })

const OrganizationSectionFieldsModel = types
  .model("OrganizationSectionsFields", {
    fiscalYearEnd: types.string,
    fiscalYearStart: types.string,
    callTypes: types.array(types.string),
    artifacts: types.optional(types.array(ArtifactFieldsModel), [])
  })

export type OrganizationSectionsSnapshotIn = SnapshotIn<typeof OrganizationSectionFieldsModel>

export const OrganizationStoreModel = types
  .model("OrganizationStore", {
    organizationConfig: types.maybeNull(OrganizationSectionFieldsModel),
  })
  .actions(self => ({
    getOrgSettings: flow(function* getOrgSettings(company: string, user_id: string) {

      try {
        const orgSettings = yield getOrgSettingsConfig(company, user_id)

        if (orgSettings.kind === "ok") {
          self.organizationConfig = OrganizationSectionFieldsModel.create(orgSettings.data)
          return true
        }
      } catch (e) {
        return false
      }
      return false
    }),
    uploadArtifact: flow(function* uploadArtifact(artifactInfo) {

      try {
        const orgSettings = yield AddArtifacts(artifactInfo)

        if (orgSettings.kind === "ok") {
          const newArtifact = {
            artifactId: artifactInfo.artifactId,
            artifactName: artifactInfo.artifactName,
            description: artifactInfo.description,
          }
          if (self.organizationConfig) {
            self.organizationConfig.artifacts.push(ArtifactFieldsModel.create(newArtifact))
          }
          return true
        }
      } catch (e) {
        return false
      }
      return false
    }),
    updateOrgSettings(updateField: string, updateValue: string | Array<{ string }>) {
      if (!self.organizationConfig) return false

      self.organizationConfig[updateField] = updateValue

      return true
    }
  }))

export type OrganizationStore = Instance<typeof OrganizationStoreModel>